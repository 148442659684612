import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "select", "movieInput", "tvShowInput"];

  selectTargetConnected(target) {
    this.#hangListener(target.selectize);
  }

  displayMovieInput() {
    this.movieInputTarget.classList.remove("d-none");
    this.tvShowInputTarget.classList.add("d-none");
  }

  displayTvShowInput() {
    this.tvShowInputTarget.classList.remove("d-none");
    this.movieInputTarget.classList.add("d-none");
  }

  openCalendar(event) {
    window.open(event.target.getAttribute("href"), "_blank");
  }

  #hangListener(selectize) {
    selectize.on("change", () => {
      const url = new URL("https://calendar.google.com/calendar/u/0/r/eventedit");

      const date = selectize.items[0];

      if (!date) {
        this.buttonTarget.setAttribute("href", "");
        this.buttonTarget.setAttribute("disabled", "");

        return;
      };

      const text = selectize.options[date].label;
      const dates = `${date}T120000/${date}T123000`;

      url.searchParams.append("text", `"${text}" was released`);
      url.searchParams.append("dates", dates);

      this.buttonTarget.setAttribute("href", url.href);
      this.buttonTarget.removeAttribute("disabled");
    });
  }
}
