import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

export default class extends Controller {
  static targets = ["input", "output"]

  transformIntoOneLineAndCopy(event) {
    let oneLineText = this.inputTarget.value.replace(/\n/g, "");
    this.outputTarget.textContent = oneLineText;

    navigator.clipboard.writeText(this.outputTarget.textContent);

    const tooltip = Tooltip.getOrCreateInstance(event.target, {title: "Copied!", trigger: "manual"});
    tooltip.show();

    setTimeout(() => tooltip.hide(), 1000);
  }
}
